@import url(https://fonts.googleapis.com/css2?family=Fraunces:wght@500&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Fraunces', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.app {
    text-align: center;
}

.app__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 0;
    background-color: white;
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 0;
    background-color: wheat;
}

.navbar__container {
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.navbar__buttons {
    display: flex;
}

.navbar__link {
    text-decoration: none;
}

.navbar__button {
    padding: 10px;
    cursor: pointer;
    font-size: 30px;
    background-color: wheat;
    color: black;
    border: none;
}

@media only screen and (max-width: 600px) {
    .navbar__container {
        align-items: center;
    }

    .navbar__buttons {
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .navbar__linkText {
        font-size: 12px;
    }

    .navbar__button {
        margin-left: 1px;
        margin-right: 1px;
        height: 33px;
    }
}
.contact__page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.contact__title {
    text-align: center;
    font-size: 40px;
    font-weight: 200;
    color: black;
    padding: 10px;
}

.contact__socialMediaContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact__socialLink {
    text-decoration: none;
    font-size: 50px;
    margin: 2px;
}

.contact__linkTitle {
    margin-left: 7px;
    color: black;
}

.icon {
    color: black;
}

@media only screen and (max-width: 600px) {
    .contact__title {
        font-size: 27px;
    }
}

.contact__buttonTitle {
    display: flex;
    align-items: center;
    border: none;
    font-size: 16px;
}

.contact__buttonTitle {
    cursor: pointer;
}

.name {
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 200px;
}

.name__title {
    font-size: 80px;
    font-weight: 600;
}

.name__subTitle {
    font-size: 36px;
    font-weight: 400;
}
