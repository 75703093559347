.name {
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 200px;
}

.name__title {
    font-size: 80px;
    font-weight: 600;
}

.name__subTitle {
    font-size: 36px;
    font-weight: 400;
}