.app {
    text-align: center;
}

.app__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 0;
    background-color: white;
}
