.contact__page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.contact__title {
    text-align: center;
    font-size: 40px;
    font-weight: 200;
    color: black;
    padding: 10px;
}

.contact__socialMediaContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact__socialLink {
    text-decoration: none;
    font-size: 50px;
    margin: 2px;
}

.contact__linkTitle {
    margin-left: 7px;
    color: black;
}

.icon {
    color: black;
}

@media only screen and (max-width: 600px) {
    .contact__title {
        font-size: 27px;
    }
}

.contact__buttonTitle {
    display: flex;
    align-items: center;
    border: none;
    font-size: 16px;
}

.contact__buttonTitle {
    cursor: pointer;
}
