.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 0;
    background-color: wheat;
}

.navbar__container {
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.navbar__buttons {
    display: flex;
}

.navbar__link {
    text-decoration: none;
}

.navbar__button {
    padding: 10px;
    cursor: pointer;
    font-size: 30px;
    background-color: wheat;
    color: black;
    border: none;
}

@media only screen and (max-width: 600px) {
    .navbar__container {
        align-items: center;
    }

    .navbar__buttons {
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .navbar__linkText {
        font-size: 12px;
    }

    .navbar__button {
        margin-left: 1px;
        margin-right: 1px;
        height: 33px;
    }
}